import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import ProductCard from "../components/product-card"
import RecipeListForEquipment from "../components/recipe-list-for-equipment"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const Breadcrumb = styled.div`
font-weight: 300
`

class EquipmentPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    const seoTitle=`The Best ${post.frontmatter.title}`

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO 
          title={seoTitle} 
          description={post.excerpt} 
          />
        <Breadcrumb>
          <Link to='/'>Home</Link> 
          &nbsp;&nbsp;<span>/</span>&nbsp;&nbsp;
          <Link to='/equipment'>Equipment</Link>
        </Breadcrumb>
        <h1>{seoTitle}</h1>
        <p
          style={{
            ...scale(-1 / 5),
            display: `block`,
            marginBottom: rhythm(1),
            marginTop: rhythm(-1),
          }}
        >
          {post.frontmatter.date}
        </p>
        {post.frontmatter.recommendation &&
          <ProductCard
            name={post.frontmatter.recommendation.name}
            asin={post.frontmatter.recommendation.asin}
            price={post.frontmatter.recommendation.price}
            image={post.frontmatter.recommendation.image.childImageSharp.sizes}
          />
        }
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr/>
        <RecipeListForEquipment
          equipment={post.frontmatter.title}
        />
        <Bio />
        <Link to='/equipment'>Click here to see my other equipment recommendations.</Link>
        <ul
          style={{
            marginTop: `10px`,
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={'/' + previous.fields.path + previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={'/' + next.fields.path + next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </Layout>
    )
  }
}

export default EquipmentPostTemplate

export const pageQuery = graphql`
  query EquipmentPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        recommendation {
          name
          image {
            childImageSharp {
              sizes(maxWidth: 630) {
                ...GatsbyImageSharpSizes
              }
            }
          }
          asin
          price
        }
      }
    }
  }
`